<template>
  <main>
    <div class="pb">
      <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
    </div>

    <Form ref="form" :model="form" :rules="validate" label-position="top">
      <Divider orientation="left">基本信息</Divider>
      <Row>
        <Col :span="9" :offset="3">
          <FormItem label="题库分类" prop="question_category_id">
            <Select v-model="form.question_category_id" class="w50">
              <Option :value="item.id" v-for="item in categorys" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="科目名" prop="name" class="w50">
            <Input :maxlength="45" show-word-limit v-model="form.name"/>
          </FormItem>
          <FormItem label="权重排序">
            <InputNumber
              :min="-32768"
              :max="32767"
              class="input-number-long"
              v-model="form.weight"
            />
            <p class="text-sm text-grey">从大到小排序</p>
          </FormItem>

          <FormItem label="是否可见">
            <i-switch v-model="form.status" size="large" :true-value="1" :false-value="0">
              <span slot="open">是</span>
              <span slot="close">否</span>
            </i-switch>
          </FormItem>
        </Col>
      </Row>

      <Divider/>
      <Row>
        <Col :span="9" :offset="3">
          <FormItem>
            <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认添加</Button>
            <Button type="success" icon="md-filing" @click="submit" v-else>保存修改</Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  </main>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      form: {
        name: "",
        question_category_id: 0,
        weight:0,
        status:1
      },
      validate: {
        name: [{ required: true, message: "请输入分类名", trigger: "blur" }],
        question_category_id: [
          {
            required: true,
            type: "number",
            min: 0,
            message: "请选择题库分类",
            trigger: "blur"
          }
        ]
      },
      categorys: []
    };
  },
  created() {
    this.loadCategorys();
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const url =
            "/questionSubject/" + (this.id ? "update/id/" + this.id : "create");
          this.$http.post(url, this.form).then(res => {
            this.$router.back();
          });
        }
      });
    },
    loadData() {
      this.$http.get("/questionSubject/detail/id/" + this.id).then(res => {
        this.form = res;
      });
    },
    loadCategorys() {
      this.$http.get("/questionCategory/list").then(res => {
        this.categorys = res.data;
        this.id && this.loadData();
      });
    }
  }
};
</script>